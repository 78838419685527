import {selectable} from "../../languages";

const langPaths = selectable.map(lang => `/${lang}`);

export function localeFromPathname(pathname: string): string | null {
  if (pathname.length == 3) {
    if (langPaths.includes(pathname)) {
      return pathname.slice(1);
    }
  }
  const match = pathname.match(/^\/([a-z]{2})\//);
  if (match) {
    // @ts-expect-error TS2322: Type 'string | undefined' is not assignable to type 'string | null'.
    return match[1];
  }
  return null;
}

export function pathnameWithoutLocale(pathname: string): string {
  const locale = localeFromPathname(pathname);
  if (locale) {
    const withoutLocale = pathname.slice(locale.length + 1);
    const outPathname = withoutLocale === "" ? "/" : withoutLocale;
    return outPathname;
  }
  return pathname;
}
