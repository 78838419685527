import {NextResponse} from "next/server";
import {GetServerSidePropsContext} from "next/types";
import {getCookiesFromString} from "@utils/cookies";

export type GetServerSidePropsResponse = GetServerSidePropsContext["res"];

export type AnyNextResponse = NextResponse | GetServerSidePropsResponse;

function getCookiesFromStrings(cookieStrings: string[]): Record<string, string> {
  return cookieStrings.reduce((acc, next) => ({...acc, ...getCookiesFromString(next)}), {});
}

function getCookiesFromStringOrArray(
  cookieStringOrArray: string | string[],
): Record<string, string> {
  return Array.isArray(cookieStringOrArray)
    ? getCookiesFromStrings(cookieStringOrArray)
    : getCookiesFromString(cookieStringOrArray);
}

function getSetCookiesFromResponse(res: GetServerSidePropsResponse): string[] {
  const rawCookies = res.getHeader("Set-Cookie");
  if (!rawCookies) {
    return [];
  }
  if (typeof rawCookies === "number") {
    return [];
  }
  if (Array.isArray(rawCookies)) {
    return rawCookies;
  }
  return [rawCookies];
}

export function responseCookiesAsObject(
  response: AnyNextResponse,
  cookieNames: string[],
): Record<string, string | undefined> {
  if ("cookies" in response) {
    return Object.fromEntries(
      cookieNames.map(cookieName => [cookieName, response.cookies.get(cookieName)?.value]),
    );
  } else {
    return getCookiesFromStringOrArray(getSetCookiesFromResponse(response));
  }
}
