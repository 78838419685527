import {MsMap} from "src/constants/MsMap";

export enum CookieKey {
  VisitId = "visitId",
  VisitorId = "visitorId",
}

/**
 *
 * @param cookieString the value of either `document.cookie` or `req.headers.cookie`
 */
export const getCookiesFromString = (cookieString = ""): Record<string, string> =>
  Object.fromEntries(cookieString?.split("; ").map(cookie => cookie.split("=")) || []);

export function expiresInOneYearOptions() {
  return {expires: new Date(Date.now() + MsMap.ONE_YEAR)};
}
