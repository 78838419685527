import {NextRequest} from "next/server";
import {GetServerSidePropsContext} from "next/types";
import {RequestCookies} from "next/dist/server/web/spec-extension/cookies";

export type GetServerSidePropsRequest = GetServerSidePropsContext["req"];

export type AnyNextRequest = NextRequest | GetServerSidePropsRequest;

export function requestHeadersAsObject(
  headers: AnyNextRequest["headers"],
): Record<string, string | string[] | undefined> {
  if (headers instanceof Headers) {
    // TODO: Not sure why we are getting the below TS error. It only occurs
    // when running yarn tsc:check and not from yarn tsc. This is certainly
    // working at runtime and .entries() is in the spec.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/Headers/entries
    // @ts-ignore TS2339: Property 'entries' does not exist on type 'Headers'.
    return Object.fromEntries(headers.entries());
  } else {
    return headers;
  }
}

export function requestCookiesAsObject(
  cookies: AnyNextRequest["cookies"],
  cookieNames: string[],
): Record<string, string | undefined> {
  if (cookies instanceof RequestCookies) {
    return Object.fromEntries(
      cookieNames.map(cookieName => [cookieName, cookies.get(cookieName)?.value]),
    );
  } else {
    return cookies;
  }
}

function isGetServerSidePropsRequest(req: AnyNextRequest): req is GetServerSidePropsRequest {
  return "res" in req;
}

function isNextRequest(req: AnyNextRequest): req is NextRequest {
  return "ip" in req;
}

export function clientIpFromAnyNextRequest(req: AnyNextRequest): string | undefined {
  if (isGetServerSidePropsRequest(req)) {
    return req.socket?.remoteAddress;
  } else if (isNextRequest(req)) {
    return req.ip;
  } else {
    return undefined;
  }
}
