import {getCookie} from "cookies-next";
import {
  AnyNextRequest,
  AnyNextResponse,
  GetServerSidePropsRequest,
  responseCookiesAsObject,
} from "src/app/_pages-transition/server";

/**
 * When we set a cookie via middleware and try to use in getServerSideProps,
 * it is not available in request or response cookies. Instead, it is available
 * via the Set-Cookie header in the response.
 *
 * If response is passed, it will be checked for a Set-Cookie header first.
 * If request is passed, it will be checked next for a cookie.
 * If neither are passed, it will try to get the cookies from the browser, which
 *   will only work in the browser.
 */

export function getCookieValue(key: string, req?: AnyNextRequest, res?: AnyNextResponse) {
  if (res) {
    const cookiesFromSetCookie = responseCookiesAsObject(res, [key]);
    if (key in cookiesFromSetCookie) {
      return cookiesFromSetCookie[key];
    }
  }
  // Note: cookies-next types are wrong, it can also accept middleware request
  return getCookie(key, {req: req as GetServerSidePropsRequest | undefined});
}
