import {RequestCookie} from "next/dist/compiled/@edge-runtime/cookies";
import {NextResponse} from "next/server";

export const LOCALE_COOKIE_NAME = "NEXT_LOCALE";

export function setLocaleClient(locale: string): void {
  document.cookie = `${LOCALE_COOKIE_NAME}=${locale}; path=/;`;
}

export const localeCookieValue: (locale: string) => Omit<RequestCookie, "name"> = locale => ({
  value: locale,
  path: "/",
  sameSite: "lax",
  secure: process.env.NODE_ENV === "production",
});

export function setLocaleServer(res: NextResponse, locale: string): NextResponse {
  res.cookies.set(LOCALE_COOKIE_NAME, locale, localeCookieValue(locale));
  return res;
}
