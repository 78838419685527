import "../../extensions";

import {NextPageContextWithStore, RootStateLocation} from "../../store/types";
import {getPublicEnvVar} from "../../utils/getEnvVar";

export const dev = !process.env.buildEnvProd && process.env.NODE_ENV !== "production";
export const buildId = process.env.BUILD_ID;
export const icomoonBaseUrl = "https://cdn.icomoon.io/122899/websitev5/style.css";
export const icomoonUrl = `${icomoonBaseUrl}?${buildId}`;

export const defaultNS = "website";
export const locizeProjectId = "b75b8ccd-4618-48b3-8447-9b1a17865232";

export const v5Pages = {
  about: "/about-us",
  blog: "/blog",
  booking: "/booking",
  careers: "/careers",
  carbonForCampus: "/carbon-for-campus",
  confirmIdentity: "/confirm-identity",
  covidCare: "/get-care/covid-care",
  covid19TravelClearance: "/get-care/covid-19-travel-testing",
  covid19Vaccination: "/get-care/covid-19-vaccination",
  covidReadyEducation: "/covid-ready-education",
  covidTreatment: "/get-care/covid-treatment",
  diabetesCare: "https://carbonhealth.com/primary-care/diabetes-management",
  download: "/download",
  getCareRoot: "/get-care",
  getCareSlug: "/get-care/[slug]",
  home: "/",
  imaging: "/get-care/imaging",
  insurance: "/insurance-pricing",
  jobs: "/jobs",
  labsAndLabResults: "/get-care/labs-and-lab-results",
  lgbtq: "/get-care/lgbtq-health-and-wellness",
  locations: "/locations",
  clinicDetails: "/locations/[slug]",
  browse: "/locations/browse",
  mentalHealth: "/get-care/mental-health",
  mobileClinic: "/mobile-clinic",
  partners: "/partners",
  pediatrics: "/get-care/pediatrics",
  press: "/press",
  primaryCare: "/get-care/primary-care",
  providers: "/providers/[slug]",
  privacy: "/privacy-policy",
  privacyPractices: "/notice-of-privacy-practices",
  reason: "/reason",
  reason2: "/reason2",
  sellYourPractice: "/sell-your-practice",
  terms: "/terms-of-use",
  testpage: "/testpage",
  ucHastings: "/uc-hastings",
  urgentCare: "/get-care/urgent-care",
  virtualCare: "/virtual-care/telehealth",
  virtualCareMental: "/get-care/mental-health",
  womensHealth: "/get-care/womens-health-concern",
  workplaceHealth: "/get-care/workplace-health",
  collegesOfFenway: "/student-health/colleges-of-fenway",
  covid19Testing: "/get-care/covid-19-testing",
} as const;

export type Pathname = (typeof v5Pages)[keyof typeof v5Pages];

export const isLocalDB = process.env.DB === "localDB";

export const getLocalPractices = async () =>
  (await fetch(`${window.location.origin}/static/data/localPractices.json?${buildId}`)).json();

export const getLocalLocations = async (): Promise<RootStateLocation[]> =>
  (await fetch(`${window.location.origin}/static/data/localLocations.json?${buildId}`)).json();

export const markerClusterUrl = "/static/img/v5/maps/clusters/m";

export const supportLink = "https://support.carbonhealth.com";

export const occupationalHealthLink = "https://occupational-health.carbonhealth.com/";

export const v5PagesTitles = {
  [v5Pages.home]: "Home",
  [v5Pages.urgentCare]: "Urgent Care",
  [v5Pages.locations]: "Locations",
  [v5Pages.clinicDetails]: "Location",
  [v5Pages.insurance]: "Insurance and Pricing",
  [v5Pages.about]: "About",
  [v5Pages.partners]: "Partners",
  [v5Pages.jobs]: "Jobs",
  [v5Pages.privacy]: "Privacy",
  [v5Pages.terms]: "Terms",
  [v5Pages.download]: "Download",
  [v5Pages.getCareRoot]: "Get Care",
  [v5Pages.reason]: "Reason",
  [v5Pages.virtualCare]: "Virtual Care",
  [v5Pages.virtualCareMental]: "Virtual Mental Care",
  [v5Pages.mobileClinic]: "Mobile Clinics",
  [v5Pages.sellYourPractice]: "Sell Your Practice",
  [v5Pages.covidReadyEducation]: "Covid Ready Education",
  [v5Pages.ucHastings]: "UC Law SF",
};

// adding mock function so scanner can catch
// @ts-expect-error TS7006: Parameter 'v' implicitly has an 'any' type.
export const i18n = {t: v => v};

export const mods = {
  clinic: "clinic",
  video: "video",
};

export const localhost = "http://localhost:5002";
export const prodHost = "https://carbonhealth.com";
export const host = dev ? localhost : prodHost;

export const patientAppUrl = (getPublicEnvVar("PATIENT_APP_URL") ||
  (dev ? "http://localhost:3002/patients" : "https://carbonhealth.com/patients")) as string;

export const laTimezone = "America/Los_Angeles";

export const downloadUrlDeeplink = "https://crbn.page.link/hicVl04Wj5";

export const supportEmail = "support@carbonhealth.com";
export const covidReadyUrl = "https://business.carbonhealth.com";
export const careersUrl = "https://jobs.lever.co/carbonhealth";
export const blogURL = "https://carbonhealth.com/blog";
export const twitterUrl = "https://twitter.com/carbonhealth";
export const facebookUrl = "https://www.facebook.com/CarbonHealth/";
export const linkedInUrl = "https://www.linkedin.com/company/carbon-health";
export const instagramUrl = "https://www.instagram.com/carbonhealth";

export const millisPerMinute = 1000 * 60;

export const widgetAllowedAsPaths = ["urgent-care", "mobile-clinic"];

export const sfCenterPos = {x: 37.7749, y: -122.4194, regionCode: "CA"};

export const googleStaticMapMarker =
  "https://carbonhealth.com/static/img/_common/marker-64x64.png?1";

export const c4BLinkedInConversionId = 2747490;
export const LEVER_URI = "https://api.lever.co/v0/postings/carbonhealth?mode=json";

export const forwardTo = async (
  res: NextPageContextWithStore["res"],
  url: string,
  code = 301,
): Promise<void> => {
  if (res) {
    // On the server, we'll use an HTTP response to
    // redirect with the status code of our choice.
    // 301 is for permenant redirects.
    res.writeHead(code, {Location: url});
    res.end();
  } else {
    window.location.href = url;
  }
  // While the page is loading, code execution will
  // continue, so we'll await a never-resolving
  // promise to make sure our page never
  // gets rendered.
  await new Promise(() => true);
};

export const fakeTimestamp = 1894485600000; // jan 13 2030

export const AUTO_FILL_FORMS = process.env.NEXT_PUBLIC_AUTO_FILL_FORMS && dev;
