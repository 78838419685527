import {setCookie} from "cookies-next";
import {AnyNextRequest, AnyNextResponse} from "../app/_pages-transition/server";
import {CookieKey, expiresInOneYearOptions} from "./cookies";
import {getCookieValue} from "src/app/_cookies/server";

export const setVisitId = (id: string) => {
  setCookie(CookieKey.VisitId, id, expiresInOneYearOptions());
};

export const setVisitorId = (id: string) => {
  setCookie(CookieKey.VisitorId, id, expiresInOneYearOptions());
};

export const getVisitId = (req?: AnyNextRequest, res?: AnyNextResponse) =>
  getCookieValue(CookieKey.VisitId, req, res);

export const getVisitorId = (req?: AnyNextRequest, res?: AnyNextResponse) =>
  getCookieValue(CookieKey.VisitorId, req, res);
